import React,{Component} from 'react';

export class Home extends Component{
    render(){
        return(
            <div>
                <h3>This is Home page</h3>
            </div>
        )
    }
}